<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8">
        <SubHeader class="mb-2">
          <b-row class="mt-2 mb-1">
            <b-col cols="3">
              <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Kursen oder Inhalten..."/>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox v-model="inactive" inline>
                <b-badge id="tt-inactive" class="mr-2" variant="primary">Inaktiv</b-badge>
                <b-tooltip target="tt-inactive" triggers="hover">Anzeige aller inaktiven Einträge</b-tooltip>
              </b-form-checkbox>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-button variant="primary" size="sm" @click="add">Neuer Eintrag</b-button>
            </b-col>
          </b-row>
        </SubHeader>
        <CourseDataTable v-if="coursesFiltered" :tableItems="coursesFiltered" @edit-course-data="edit"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <ScrollToTop/>
    <CourseDataModal :method="method" :data="data" @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import CourseDataTable from "@/components/fg-software/courseData/CourseDataTable";
import CourseDataModal from "@/components/fg-software/courseData/CourseDataModal.vue";

export default {
  name: "CourseDataManage",
  components: {
    SubHeader,
    ScrollToTop,
    CourseDataTable,
    CourseDataModal
  },
  data() {
    return {
      search: '',
      data: null,
      courseData: null,
      method: null,
      inactive: false
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    add() {
      this.data = null
      this.method = 'add';
      setTimeout(() => {
        this.$bvModal.show('courseDataModal');
      }, 100);
    },
    edit(data) {
      this.data = data;
      this.method = 'edit';
      setTimeout(() => {
        this.$bvModal.show('courseDataModal');
      }, 100);
    },
    async getCourseData() {
      await getRequest('course-data/fg/all', null, this)
          .then((response) => {
            this.courseData = response.data
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getCourseData()
      this.setLoadingState(false)
    }
  },
  computed: {
    coursesFiltered() {
      return _.filter(this.courseData, (entry) => {
        if(!this.inactive) {
          return (entry.displayname.toLowerCase().includes(this.search.toLowerCase()) ||
                  entry.description.toLowerCase().includes(this.search.toLowerCase())) && entry.inactive === false
        }
        else {
          return entry.displayname.toLowerCase().includes(this.search.toLowerCase()) || entry.description.toLowerCase().includes(this.search.toLowerCase())
        }
      })
    }
  },
  async created() {
    this.refresh()
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/Tables';
</style>
