<template>
  <b-modal id="courseDataModal" :title="title" size="xl" @ok="ok" @hidden="reset" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="6">
          <label>Bezeichnung:</label>
          <b-form-input v-model="form.displayname" placeholder="Bezeichnung..." trim/>
        </b-col>
        <b-col cols="2" v-if="method === 'edit'">
          <label>Inaktiv: <b-icon-question-circle-fill id="tt-active" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="form.inactive" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-active" triggers="hover">Inaktive Kursdaten</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row>
        <label class="mt-2">Inhalt:</label>
        <b-form-textarea v-model="form.description"/>
        <div class="mt-2 mb-n3">
          <p>
            Die Bestandteile der Kursdaten müssen mit einem ";" (Semikolon) getrennt werden. Bsp.:
            Anaconda3-2021.05-Windows-x86_64.exe;Redis-x64-3.2.100.zip
          </p>
        </div>
      </b-form-row>
      <b-form-row>
        <label class="mt-2">Notiz:</label>
        <b-form-textarea v-model="form.note"/>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
import {putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "CourseDataModal",
  props: ['method', 'data'],
  data() {
    return {
      form: {
        id: null,
        displayname: '',
        description: '',
        note: '',
        inactive: false
      }
    }
  },
  methods: {
    reset() {
      this.form = {
        id: null,
        displayname: '',
        description: '',
        note: '',
        inactive: false
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    async ok() {
      switch (this.method) {
        case 'edit':
          await this.updateRow();
          break;
        case 'add':
          await this.addRow();
          break;
      }
      this.reset();
    },
    async onShow() {
      if(this.method === 'edit') {
        this.form.id = this.data.id
        this.form.displayname = this.data.displayname
        this.form.description = this.data.description
        this.form.note = this.data.note
        this.form.inactive = this.data.inactive
      }
      else {
        this.reset()
      }
    },
    async addRow() {
      let data = {
        displayname: this.form.displayname,
        description: this.form.description,
        note: this.form.note
      }

      await putRequest('course-data/fg/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.refresh()
          })
    },
    async updateRow() {
      let data = {
        id: this.form.id,
        displayname: this.form.displayname,
        description: this.form.description,
        note: this.form.note,
        inactive: this.form.inactive
      }

      await putRequest('course-data/fg/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.refresh()
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    ...mapGetters(['userColorAccessibility']),
    title() {
      if(this.method === 'edit') {
        return 'Kursdaten ändern'
      }
      return 'Kursdaten hinzufügen'
    },
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
